import React from 'react';


const Skills = () => {
    return (
        <div className="container" id="skills">
            <div className="row">
            <h2 className="mt-4 text-uppercase text-black text-center  mb-5  offset-md-5 offset-md-4 offset-3 border-bottom border-success font-weight-bold ">Skills</h2>
                </div>


<div className="row p-1 ">

<div className="col-3  p-xl-5 border border-secondary text-center p-2">
   <img src="https://img.icons8.com/color/2x/html-5.png" alt="" style={{ height:"75px"}}/> 
   </div>

   <div className="col-3 p-xl-5 border border-secondary text-center  p-2">
   <img src="https://img.icons8.com/color/5x/css3.png" alt="" style={{ height:"75px"}}/> 
   </div>


   <div className="col-3 p-xl-5 border border-secondary text-center  p-2">
   <img src="https://img.icons8.com/color/2x/bootstrap.png" alt="" style={{ height:"75px"}}/> 
   </div>

   <div className="col-3 p-xl-5 border border-secondary text-center  p-2">
   <img src="https://img.icons8.com/color/2x/javascript.png" alt="" style={{ height:"75px"}}/> 
   </div>


   <div className="col-3 p-xl-5 border border-secondary text-center  p-2">
   <img src="https://img.icons8.com/ultraviolet/2x/react.png" alt="" style={{ height:"75px"}}/> 
   </div>


   <div className="col-3 p-xl-5 border border-secondary text-center  p-2">
   <img src="https://img.icons8.com/color/2x/nodejs.png" alt="" style={{ height:"75px"}}/> 
   </div>

   <div className="col-3 p-xl-5 border border-secondary text-center  p-2">
   <img src="https://img.icons8.com/ios-filled/2x/sql.png" alt="" style={{ height:"75px"}}/> 
   </div>

   <div className="col-3 p-xl-5 border border-secondary text-center  p-2">
   <img src="https://img.icons8.com/dusk/64/000000/wordpress.png" alt=""  style={{ height:"75px"}}/> 
   </div>


   <div className="col-3 p-xl-5 border border-secondary text-center  p-2">
   <img src="https://img.icons8.com/color/2x/python.png" alt="" style={{ height:"75px"}}/> 
   </div>


   <div className="col-3 p-xl-5 border border-secondary text-center  p-2">
   <img src="https://img.icons8.com/color/48/000000/django.png" alt="" style={{ height:"75px"}}/> 
   </div>


   <div className="col-3 p-xl-5 border border-secondary text-center  p-2">
   <img src="https://img.icons8.com/color/48/000000/c-programming.png" alt="" style={{ height:"75px"}}/> 
   </div>


   <div className="col-3 p-xl-5 border border-secondary text-center  p-2">
   <img src="https://img.icons8.com/dusk/64/000000/java-coffee-cup-logo.png" alt="" style={{ height:"75px"}}/> 
   </div>


   <div className="col-3 p-xl-5 border border-secondary text-center  p-2">
   <img src="https://img.icons8.com/ios-glyphs/2x/github-2.png" alt="" style={{ height:"75px"}}/> 
   </div>
   
   <div className="col-3 p-xl-5 border border-secondary text-center  p-2">
   <img src="https://img.icons8.com/ios-filled/2x/mysql-logo.png" alt="" style={{ height:"75px"}}/> 
   </div>


   <div className="col-3 p-xl-5 border border-secondary text-center  p-2">
   <img src="https://img.icons8.com/color/2x/c-plus-plus-logo.png" alt="" style={{ height:"75px"}}/> 
   </div>


</div>

            </div>
     
    )
}

export default Skills
